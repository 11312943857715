module.exports = {
  siteTitle: 'Nikos Papageorgiou | Front End Engineer',
  siteDescription:
    'Nikos Papageorgiou is a front end  engineer based in Athens, Greece who specializes in developing high-quality websites and applications.',
  siteKeywords:
    'Nikos Papageorgiou, nikospap, front end engineer, front-end engineer, web developer, javascript, greece',
  siteUrl: 'https://nikospap.com',
  siteLanguage: 'en_US',

  googleVerification: 'KSTWsfvBPhaV7mGXTCEN0_2BNNpeCzGgnOscpXiZ4dU',

  name: 'Nikos Papageorgiou',
  location: 'Athens, Greece',
  email: 'admin@nikospap.com',
  github: 'https://github.com/nikospapcom',
  socialMedia: [
    {
      name: 'Github',
      url: 'https://github.com/nikospapcom',
    },
    {
      name: 'Linkedin',
      url: 'https://www.linkedin.com/in/npapageorgiou/',
    },
    {
      name: 'Instagram',
      url: 'https://www.instagram.com/_nikos_pap/',
    },
    {
      name: 'Twitter',
      url: 'https://twitter.com/Nick_Pap21',
    },
  ],

  navLinks: [
    {
      name: 'About',
      url: '#about',
    },
    {
      name: 'Experience',
      url: '#jobs',
    },
    {
      name: 'Work',
      url: '#projects',
    },
    {
      name: 'Contact',
      url: '#contact',
    },
  ],

  twitterHandle: '@Nick_Pap21',
  googleAnalyticsID: 'UA-72531041-1',

  navHeight: 100,

  greenColor: '#64ffda',
  navyColor: '#0a192f',
  darkNavyColor: '#020c1b',

  srConfig: (delay = 200) => ({
    origin: 'bottom',
    distance: '20px',
    duration: 500,
    delay,
    rotate: { x: 0, y: 0, z: 0 },
    opacity: 0,
    scale: 1,
    easing: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
    mobile: true,
    reset: false,
    useDelay: 'always',
    viewFactor: 0.25,
    viewOffset: { top: 0, right: 0, bottom: 0, left: 0 },
  }),
};
